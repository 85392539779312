<template>
  <!-- PO单查询 -->
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card>
      <el-form ref="queryForm" :model="queryParams" size="small" label-width="90px" @submit.native.prevent>
        <div class="formModel">
          <el-form-item label="PO单号" prop="cTicketId">
            <el-input class="formItem" v-model="queryParams.cTicketId" placeholder="请输入PO单号" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item label="订单号" prop="orderNo">
            <el-input class="formItem" v-model="queryParams.orderNo" placeholder="请输入订单号" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item label="腾讯工单号" prop="ticketId">
            <el-input class="formItem" v-model="queryParams.ticketId" placeholder="请输入服务工单号" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item label="工单标题" prop="memo">
            <el-input class="formItem" v-model="queryParams.memo" placeholder="请输入备注" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item label="专项-服务项" prop="svrItem">
            <el-select v-model="queryParams.svrItem" multiple placeholder="请选择服务项目" clearable
              @keyup.enter.native="handleQuery">
              <el-option v-for="item in svrItemOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务信息" prop="serviceInfo">
            <el-input class="formItem" v-model="queryParams.serviceInfo" placeholder="请输入服务信息" clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item label="一级机房" prop="idcpName">
            <el-cascader v-model="queryParams.idcpName" :options="depOptions" :props="{
              multiple: true,
              expandTrigger: 'hover',
              label: 'dept_name',
              value: 'dept_name',
              children: 'children',
            }" collapse-tags clearable @keyup.enter.native="handleQuery"
            ></el-cascader>
          </el-form-item>

          <!-- <el-form-item label="园区" prop="campusName">
            <el-select v-model="queryParams.campusName" multiple placeholder="请选择校区名称" clearable
              @keyup.enter.native="handleQuery">
              <el-option v-for="item in campusNameOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="工单状态" prop="status">
            <el-select v-model="queryParams.status" placeholder="请选择工单状态" clearable @keyup.enter.native="handleQuery">
              <el-option v-for="item in orderStatusOptions" :key="item.id" :label="item.value"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="创建时间">
            <el-date-picker 
            v-model="createdTime" 
            type="daterange" 
            class="formItem" 
            value-format="yyyy-MM-dd"
            range-separator="至" 
            start-placeholder="开始时间" 
            end-placeholder="结束时间" 
            clearable
            @keyup.enter.native="handleQuery">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="SLA时间">
            <el-date-picker v-model="slaTime" type="daterange" class="formItem" value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" clearable
              @keyup.enter.native="handleQuery">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="完成时间">
            <el-date-picker v-model="cEndTime" type="daterange" class="formItem" value-format="yyyy-MM-dd"
              range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" clearable
              @keyup.enter.native="handleQuery">
            </el-date-picker>
          </el-form-item>

          <div class="formItem btns">
            <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery">搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" @click="handleFormReset('queryForm')">重置
            </el-button>
          </div>
        </div>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px; padding-bottom: 20px">
      <el-button icon="el-icon-download" size="small" type="primary" @click="handleExport">导出
      </el-button>
      <el-table v-loading="loading" :data="list" style="width: 100%; margin-top: 20px">
        <el-table-column property="cTicketId" width="160" label="PO单号">
          <template slot-scope="scope">
            <a :href="getTicketUrl(scope.row.cTicketId)" target="_blank" class="link">{{ scope.row.cTicketId }}</a>
          </template>
        </el-table-column>

        <el-table-column property="orderNo" width="160" label="PO订单号"></el-table-column>
        <el-table-column property="ticketId" width="160" label="腾讯工单号">
          <template slot-scope="scope">
            <span class="ticketId" @click="getDetail(scope.row)">{{ scope.row.ticketId }}</span>
          </template>
        </el-table-column>
        <el-table-column property="serviceInfo" width="255" label="服务信息"></el-table-column>
        <el-table-column property="svrItem" width="160" label="专项(服务项)"></el-table-column>
        <!-- <el-table-column property="idcName" width="160" label="机房管理单元"></el-table-column> -->
        <el-table-column property="idcpName" width="160" label="一级机房"></el-table-column>
        <el-table-column property="campusName" width="160" label="园区"></el-table-column>
        <el-table-column property="num" width="160" label="待操作设备数量"></el-table-column>
        <el-table-column property="createTime" width="160" label="订单创建时间"></el-table-column>
        <el-table-column property="slaTime" width="160" label="超时时间"></el-table-column>
        <el-table-column property="serviceName" width="160" label="服务商名称"></el-table-column>
        <el-table-column property="servicePersonInfo" width="160" label="服务商接口人信息"></el-table-column>
        <el-table-column property="demandPersonInfo" width="160" label="需求方联系人信息"></el-table-column>
        <el-table-column property="businessManager" width="160" label="服务商商务经理"></el-table-column>
        <el-table-column property="memo" width="160" label="备注信息"></el-table-column>
        <el-table-column property="engineerName" width="160" label="处理工程师名称"></el-table-column>
        <el-table-column property="cEndTime" width="160" label="工单结单时间"></el-table-column>
        <el-table-column property="reject" width="160" label="驳回状态">
          <template slot-scope="scope">
            <span
              :style="scope.row.reject === 1 ? 'color:#f40d0e;font-weight:bold;' : 'color:#1381e6;font-weight:bold;'">
              {{ scope.row.reject === 1 ? '驳回' : '正常' }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column fixed="right" align="left" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="remind(scope.row)">催办</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination :current-page.sync="pageParams.page" layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[25, 50, 100]" :page-size.sync="pageParams.limit" :total="total" style="margin-top: 20px"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import { itPOservice, deptTree, exportITPO, itPOserviceDetail, itPOserviceAllSpecial } from '@/api/monitor';
import { removeEmptyStringFields } from '@/utils/common'
export default {
  name: 'itPOservice',
  data() {
    return {
      // 专项（服务项）
      svrItemOptions:[],
      loading: false,
      total: 0,
      list: [],
      queryParams: {
        orderNo: '',
        ticketId: '',
        svrItem: [],
        idcpName: [],
        campusName: [],
        status: '',
        memo: '',
        cTicketId: '',
        serviceInfo: '',
        createTimeStart: '',
        createTimeEnd: '',
        slaTimeStart: '',
        slaTimeEnd: '',
        cEndTimeStart: '',
        cEndTimeEnd: ''
      },
      pageParams: {
        page: 1,
        limit: 25,
      },
      cEndTime: [], // 结单时间
      createdTime: [], //创建时间
      slaTime: [], //sla超时时间

      orderStatusOptions: [
        //工单状态数据
        {
          id: 1,
          value: '未结单',
        },
        {
          id: 2,
          value: '已结单',
        },
      ],
      timeoutStatusOptions: [
        //超时状态
        {
          id: 1,
          value: '临近超时',
        },
        {
          id: 2,
          value: '已超时',
        },
        {
          id: 0,
          value: '未超时',
        },
        {
          id: 3,
          value: '尾单',
        },
      ],
      remindOptions: [
        //是否催办数据
        {
          id: 1,
          value: '已催办',
        },
        {
          id: 0,
          value: '未催办',
        },
      ],
      depOptions: [], //一级机房数据
    };
  },
  created() {
    if (sessionStorage.getItem('it_po')) {
      this.queryParams = JSON.parse(sessionStorage.getItem('it_po'));
      if (this.queryParams.startCreateTime) {
        this.createdTimeRange = [this.queryParams.startCreateTime, this.queryParams.endCreateTime]
      }
    }
    this.fetchSvrItemOptions();
    this.deptTree();
    this.getList();
  },
  methods: {
    //添加快捷语句
    add(item) {
      this.ruleForm.remindMessage = item;
    },
    //导出
    recordClose() {
      this.recordVisible = false;
    },
    //一级机房数据
    deptTree() {
      deptTree().then((res) => {
        this.depOptions = res?.data?.data;
      });
    },
    //查看详情
    getDetail(row) {
      this.$router.push({
        path: '/appManage/monitor/itPOservice/detail',
        query: {
          ticketId: row.ticketId,
        },
      });
    },
    //格式化参数
    formattingParams() {
      let params = JSON.stringify(this.queryParams);
      let paramsNew = JSON.parse(params);
      if (this.cEndTime.length) {
        paramsNew.cEndTimeStart = `${this.cEndTime[0]} 00:00:00`; //结单开始时间
        paramsNew.cEndTimeEnd = `${this.cEndTime[1]} 23:59:59`; //结单结束时间
      }
      if (this.createdTime.length) {
        paramsNew.createTimeStart = `${this.createdTime[0]} 00:00:00`; //结单开始时间
        paramsNew.createTimeEnd = `${this.createdTime[1]} 23:59:59`; //结单结束时间
      }
      if (this.slaTime.length) {
        paramsNew.slaTimeStart = `${this.slaTime[0]} 00:00:00`; //创建开始时间
        paramsNew.slaTimeEnd = `${this.slaTime[1]} 23:59:59`; //创建截止时间
      }

      paramsNew.idcpName = this.queryParams.idcpName.map((row) => row.at(-1)); //一级机房
      // paramsNew.idcpName = paramsNew.idcpName.join(';');
      console.log("查询参数：" + paramsNew)
      return removeEmptyStringFields(paramsNew)
    },
    //导出
    handleExport() {
      this.fullscreenLoading = true;
      exportITPO(this.formattingParams())
        .then((res) => {
          const blob = new Blob([res.data]);
          saveAs(blob, `PO单_${new Date().getTime()}.xlsx`);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    //列表
    getList() {
      this.loading = true;
      itPOservice({ ...this.pageParams, ...this.formattingParams() })
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.data;
            this.total = res.data.count;
            this.loading = false;
          } else {
            this.$message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.pageParams.page = 1;
      this.pageParams.limit = 25;
      this.getList();
    },
    // 重置
    handleFormRest(formName) {
      sessionStorage.removeItem('network_params');
      this.queryParams = {
        orderNo: '',
        ticketId: '',
        svrItem: [],
        idcpName: [],
        campusName: [],
        status: '',
        memo: '',
        cTicketId: '',
        serviceInfo: '',
        createTimeStart: '',
        createTimeEnd: '',
        slaTimeStart: '',
        slaTimeEnd: '',
        cEndTimeStart: '',
        cEndTimeEnd: ''
      },
      this.cEndTime = [];
      this.createdTime = [];
      this.slaTime = [];
      this.handleQuery();
    },
    // 监听每页条数改变
    handleSizeChange(newSize) {
      this.pageParams.limit = newSize;
      this.pageParams.page = 1;
      this.getList();
    },
    // 监听当前页面变化
    handleCurrentChange(newPage) {
      this.pageParams.page = newPage;
      this.getList();
    },

    async fetchSvrItemOptions() {
      try {
        const response = await itPOserviceAllSpecial(); // 调用接口函数
        if (!response?.error) {
          this.svrItemOptions = response.data.map(item => ({
            id: item.id, // 假设返回的数据中有 value 字段
            value: item.value  // 假设返回的数据中有 label 字段
          }));
        } else {
          this.$message.error(response.error || '获取服务项失败');
        }
      } catch (error) {
        console.error('请求失败:', error);
        this.$message.error('请求服务项失败');
      }
    },
    getTicketUrl(ticketId){
      return `${process.env.VUE_APP_TICKET_URL}appManage/tickets/details?params=${ticketId}`;
    },
    
  },
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm .el-form-item {
  margin-bottom: 16px !important;
}

.item-shortcut-statement {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
    color: #333;
  }
}

.ticketId {
  color: #1890ff;
  cursor: pointer;
}

.standard-blue-link {
  color: #1890ff;
  text-decoration: none;
}

.formModel {
  display: flex;
  flex-flow: row wrap;
}

.formItem {
  // width: 175px !important;
  margin-bottom: 20px;
}

::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-card__body {
  padding: 15px 20px 0 20px !important;
}

.btns {
  margin-left: 20px;
}

.link {
  color: #007BFF; /* 设置链接颜色为蓝色 */
  text-decoration: none; /* 去掉下划线 */
}

.link:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                size: "small",
                "label-width": "90px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "formModel" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "PO单号", prop: "cTicketId" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入PO单号", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.cTicketId,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "cTicketId", $$v)
                          },
                          expression: "queryParams.cTicketId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号", prop: "orderNo" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入订单号", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.orderNo,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "orderNo", $$v)
                          },
                          expression: "queryParams.orderNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "腾讯工单号", prop: "ticketId" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: {
                          placeholder: "请输入服务工单号",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.ticketId,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "ticketId", $$v)
                          },
                          expression: "queryParams.ticketId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单标题", prop: "memo" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入备注", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.memo,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "memo", $$v)
                          },
                          expression: "queryParams.memo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专项-服务项", prop: "svrItem" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择服务项目",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.svrItem,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "svrItem", $$v)
                            },
                            expression: "queryParams.svrItem"
                          }
                        },
                        _vm._l(_vm.svrItemOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务信息", prop: "serviceInfo" } },
                    [
                      _c("el-input", {
                        staticClass: "formItem",
                        attrs: { placeholder: "请输入服务信息", clearable: "" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.serviceInfo,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "serviceInfo", $$v)
                          },
                          expression: "queryParams.serviceInfo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级机房", prop: "idcpName" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.depOptions,
                          props: {
                            multiple: true,
                            expandTrigger: "hover",
                            label: "dept_name",
                            value: "dept_name",
                            children: "children"
                          },
                          "collapse-tags": "",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.queryParams.idcpName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "idcpName", $$v)
                          },
                          expression: "queryParams.idcpName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择工单状态",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status"
                          }
                        },
                        _vm._l(_vm.orderStatusOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.createdTime,
                          callback: function($$v) {
                            _vm.createdTime = $$v
                          },
                          expression: "createdTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SLA时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.slaTime,
                          callback: function($$v) {
                            _vm.slaTime = $$v
                          },
                          expression: "slaTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "完成时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.cEndTime,
                          callback: function($$v) {
                            _vm.cEndTime = $$v
                          },
                          expression: "cEndTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "formItem btns" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-search"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v("搜索 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleFormReset("queryForm")
                            }
                          }
                        },
                        [_vm._v("重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "15px", "padding-bottom": "20px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-download",
                size: "small",
                type: "primary"
              },
              on: { click: _vm.handleExport }
            },
            [_vm._v("导出 ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: { data: _vm.list }
            },
            [
              _c("el-table-column", {
                attrs: { property: "cTicketId", width: "160", label: "PO单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "link",
                            attrs: {
                              href: _vm.getTicketUrl(scope.row.cTicketId),
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.cTicketId))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "orderNo", width: "160", label: "PO订单号" }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "ticketId",
                  width: "160",
                  label: "腾讯工单号"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "ticketId",
                            on: {
                              click: function($event) {
                                return _vm.getDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(scope.row.ticketId))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  property: "serviceInfo",
                  width: "255",
                  label: "服务信息"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "svrItem",
                  width: "160",
                  label: "专项(服务项)"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "idcpName", width: "160", label: "一级机房" }
              }),
              _c("el-table-column", {
                attrs: { property: "campusName", width: "160", label: "园区" }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "num",
                  width: "160",
                  label: "待操作设备数量"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "createTime",
                  width: "160",
                  label: "订单创建时间"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "slaTime", width: "160", label: "超时时间" }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "serviceName",
                  width: "160",
                  label: "服务商名称"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "servicePersonInfo",
                  width: "160",
                  label: "服务商接口人信息"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "demandPersonInfo",
                  width: "160",
                  label: "需求方联系人信息"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "businessManager",
                  width: "160",
                  label: "服务商商务经理"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "memo", width: "160", label: "备注信息" }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "engineerName",
                  width: "160",
                  label: "处理工程师名称"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "cEndTime",
                  width: "160",
                  label: "工单结单时间"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "reject", width: "160", label: "驳回状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              scope.row.reject === 1
                                ? "color:#f40d0e;font-weight:bold;"
                                : "color:#1381e6;font-weight:bold;"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.reject === 1 ? "驳回" : "正常"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              "current-page": _vm.pageParams.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-sizes": [25, 50, 100],
              "page-size": _vm.pageParams.limit,
              total: _vm.total
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.pageParams, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pageParams, "limit", $event)
              },
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }